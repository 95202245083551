body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.DayPicker_focusRegion {
  position: fixed !important
}
  
.DayPicker_transitionContainer {
  background-color: #fff !important;
  border: 1px solid #dbdbdb !important
}
 
.DateRangePicker_picker {
  z-index: 100 !important;
}